<!-- 百问百答 -->
<template>
  <div class="eg_hygy">
    <div class="e_gygwef">一、关于公司</div>
    <div class="e_gygwef">1.车世际属于哪家公司？这家公司实力怎么样?</div>
    <div class="ef_tftwd">
      “车世际”是四川车世际科技有限公司打造的“新能源共享汽车服务”平台。公司以“值得信赖的出行服务企业”为愿景，将互联网、车联网以及新能源科技，应用于共享出行领域，致力于成为低碳、健康、共享的优质出行服务商，为客户提供“安全、便捷、品质”出行服务的科技创新型企业。
    </div>
    <div class="e_gygwef">2.车世际的车由哪家公司生产的？</div>
    <div class="ef_tftwd">
      车世际使用车辆的生产商是东风汽车集团有限公司（简称“东风公司”）是中央直管的特大型汽车企业，总部位于武汉，现有总资产3256亿元，位居世界500强第65位、中国企业500强第15位、中国制造业500强第3位。
    </div>
    <div class="e_gygwef">3.公司领导人介绍</div>
    <div class="ef_tftwd">
      （1）周洪锦——车世际创始人兼总经理&emsp;&emsp;西南交大信息科学与技术学院在职研究生，汽后市场5年管理工作经验，2021年8月开创车世际。
    </div>
    <div class="ef_tftwd">
      （2）张云——CTO&emsp;&emsp;成都电子科技大学毕业，2017-2020年北京融联科技有限公司任总经理，2021年加盟四川车世际科技有限公司。
    </div>
    <div class="ef_tftwd">（3）李平——天使投资人</div>
    <div class="ef_tftwd">（4）李波——COO</div>
    <div class="e_gygwef">4.车世际总公司在哪里？</div>
    <div class="ef_tftwd">公司名称：四川车世际科技有限公司</div>
    <div class="ef_tftwd">
      公司地址：四川省成都市成华区华泰路28号安格斯卓越中心1001室/1008室
    </div>
    <div class="ef_tftwd">服务电话：028-84391136</div>
    <div class="e_gygwef">5.公司的网络安全问题怎么防范？</div>
    <div class="ef_tftwd">
      目前我们使用的服务器是阿里云的ECS云服务器，服务器我们使用了web应用防火墙和防DOSS攻击的监控软件，数据方面我们使用了阿里云的RDS金融级别数据库，金融级别的意义就在于防攻击，数据实时备份。所以理论上来讲只要不是内部或知名黑客，攻击进入系统的可能很小。同时我们每日也都在做系统优化补丁。
    </div>
    <div class="e_gygwef">二、关于产品</div>
    <div class="e_gygwef">1.网约车租赁业务的前景：</div>
    <div class="ef_tftwd">
      衣食住行作为人们基本的四大需求蕴含巨大的市场价值，历来都是企业占据消费者心智所在之处。交通部长李小鹏在部长通道答记者问的时候说到，对于网约车是采取积极支持的态度，政府总体上支持网约车新业态发展，同时城市公共交通缓慢发展以及失业率提升给网约车企业创造了广阔的市场空间。
    </div>
    <div class="e_gygwef">2.成都市市场规模：</div>
    <div class="ef_tftwd">
      成都出台了《2021年大气污染防治工作行动方案》，其中在治污减排方面，要求全市新增（含更换）的网约车全部为新能源汽车。将力争做到2021年底新能源汽车占比达到4%，即20万辆，目前已完成8万辆，尚有12万辆的硬指标缺口。
    </div>
    <div class="e_gygwef">3.怎么保证合作伙伴长期、稳定、安全收益：</div>
    <div class="ef_tftwd">
      1、合作伙伴的钱不是拿来做公司金融投资，而是资金变成了车，车只要在市面上运营，就能得到长期的收益；2、公司对司机采取的很多福利包含零抽成、低规费使得车辆使用率很高，因此合作伙伴能够得到稳定的收益；3、从国家到成都市政府都大力推进新能源网约车的规范运营，所以公司的业务完全合法合规，从而为合作伙伴的资产变现提供了安全保障。
    </div>
    <div class="e_gygwef">
      4.车的价格为什么低于官方指导价，是不是有什么猫腻：
    </div>
    <div class="ef_tftwd">
      车世际是东风集团的战略合作伙伴，致力于互联网+新能源出行服务，每辆车都直接从生产线发车，不是普通的经销商，我们不以卖车为业务，所以我们的价格远低于经销商。
    </div>
    <div class="e_gygwef">5.车的参数及优势？</div>
    <div class="ef_tftwd">
      车世际的产品是东风汽车集团新能源领域的扛鼎之作东风E70中级轿车。
    </div>
    <div class="ef_tftwd">它的参数主要有以下几点：</div>
    <img class="iihiwd" src="../../image/peizhiddwd.png" alt="" />
    <div class="ef_tftwd">优势：</div>
    <div class="ef_tftwd">
      精细开源节流：保养成本仅为传统车的30%；每公里电费不足7分钱。
    </div>
    <div class="ef_tftwd">
      精益节能环保：充电时间减少33%；百公里电耗低至13kwh。
    </div>
    <div class="ef_tftwd">
      三电系统质保8年或15万公里；电芯质保可达8年或60万公里。
    </div>
    <div class="e_gygwef">6.车世际的市场拓展规划？</div>
    <div class="ef_tftwd">
      第一阶段：进入市场初期，市场覆盖500台。完成成都区域的投放。
    </div>
    <div class="ef_tftwd">
      第二阶段：市场升级，市场覆盖1000台。公司直投并招募城市合伙人成立分公司。
    </div>
    <div class="ef_tftwd">
      第三阶段：市场升级，市场覆盖5000台。招募城市合伙人在下级城市发展布局。
    </div>
    <div class="ef_tftwd">
      第四阶段：实现车联网互联互通，市场覆盖10000台，覆盖西南大部分区域内的投放。
    </div>
    <div class="e_gygwef">7.车辆投放市场，遗失了或破损了怎么办?</div>
    <div class="ef_tftwd">
      每辆车都安装有GPS，不会出现遗失的问题；除了GPS，公司还有专业的资产管理团队，确保合作伙伴的资产安全。车辆如有破损由司机配合保险公司处理，并且所有车辆都有定期维保的服务。
    </div>
    <div class="e_gygwef">8.公司产品有没有竞争对手？</div>
    <div class="ef_tftwd">
      新能源车领域有很多友商，但仅限于车辆售卖，招募合伙人的模式目前只有车世际。
    </div>
    <div class="e_gygwef">9.已经在哪些城市投放了车辆？</div>
    <div class="ef_tftwd">目前仅在成都市场投放。</div>
    <div class="e_gygwef">10.怎么样解决合作伙伴监督运营？</div>
    <div class="ef_tftwd">
      合伙人可根据APP绑定车辆，定位LBS随时监督所购车辆是否是正常运营，并可将不良运营情况反馈给公司予以及时处理。
    </div>
    <div class="e_gygwef">11.客户端有专利吗？</div>
    <div class="ef_tftwd">专利78项，软著22项，商标89项，域名15项。</div>
    <div class="e_gygwef">三、关于投放</div>
    <div class="e_gygwef">1.车辆投放的流程？</div>
    <div class="ef_tftwd">
      签约购买（15天新车交付，不影响合作伙伴当月收益）-上牌、保险（4天）-绑定司机-投入运营
    </div>
    <div class="e_gygwef">2.公司目前有多少辆车流通到市场？</div>
    <div class="ef_tftwd">成都400辆（燃油车）</div>
    <div class="e_gygwef">四、关于司机</div>
    <div class="e_gygwef">1.司机合作明细</div>
    <div class="ef_tftwd">签约合作模式，底薪4000元，按平台运营规则执行，月流水超过8000元-10000元，提成70%，1万以上90%提成。</div>
    <div class="e_gygwef">2.我们产品适合招募哪些司机？</div>
    <div class="ef_tftwd">
      有驾照，无犯罪前科，无酒驾，年龄59岁以下，无心脏病、残疾等身体健康，预期收入在7000元-15000元/月之间的司机从业者。
    </div>
    <div class="e_gygwef">3.司机和我们合作有什么好处？</div>
    <div class="ef_tftwd">如果300公里/天，司机能收入1000元以上。</div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.eg_hygy {
  padding: 32px;
}
.e_gygwef {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}
.ef_tftwd {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-indent: 1em;
}
.iihiwd {
  width: 100%;
  height: 100%;
}
</style>